import React, { useState, useEffect, useCallback } from "react"
import {
    Box,
    AppBar,
    Toolbar,
    Alert,
    Container,
} from "@mui/material"
import Cookies from "js-cookie"
// import "./Navbar.css"
import DropdownMenu from "./DropdownMenu/DropdownMenu"
import CloseRoundedIcon from "@mui/icons-material/CloseRounded"
import CloseIcon from "@mui/icons-material/Close"
import { logo } from "../../assets/navbar"
import Profile from "./Profile/Profile"
import LogInPopUp from "../../components/shared/LogInPopUp/LogInPopUp"
import { useHistory } from "react-router-dom"
import { ThemeProvider, createTheme } from "@mui/material/styles"
import { DashboardTag } from "../../interfaces/DashboardTag"
import { InfographicTag } from "../../interfaces/InfographicTag"
import MenuIcon from "@mui/icons-material/Menu"
import TVLButton from "../../components/shared/TVLButton/TVLButton"
import { getUserAccount } from "../../utils/login"
import DrawerLeft from "./DrawerLeft/DrawerLeft"
import DrawerRight from "./DrawerRight/DrawerRight"
import style from "./Navbar.module.css"
import { NavBarTheme } from "../../theme/navbar"
import {ReactComponent as Crown} from '../../assets/navbar/crown_filled.svg'
import { DashboardType, InfographicType } from "../../interfaces/FilterTypes"

const Navbar = () => {
    const [openLogIn, setOpenLogIn] = useState<boolean>(false)
    const [openDrawer, setOpenDrawer] = useState<string>("")
    const [activeAccordion, setActiveAccordion] = useState<string>("")
    const [isCollapse, setIsCollapse] = useState<boolean>(true)
    const [user, setUser] = useState(getUserAccount())
    const [openAlert, setOpenAlert] = useState<boolean>(
        user?.getOrgPending().length > 0
    )
    // isLoggedIn is from local Storage
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(
        window.localStorage.getItem("isLoggedIn") === "true"
    )
    const pendingStatusSeen = window.sessionStorage.getItem("pendingStatusSeen")

    const history = useHistory()

    const dashboard_tag: DashboardTag[] = require("../../variable/dashboard_tag.json")
    const infographicTag: InfographicTag[] = require("../../variable/infographic_tag.json")
    const handleClickPathname = useCallback((pathname: string, category: string): void => {
        let tagList: {category_thai: string, category_eng: string}[] = []
        let tagType: "dashboard" | "infographic" | "" = "" 
        if(pathname.includes('dashboard')){
            tagType = "dashboard"
            tagList = dashboard_tag
        } else if(pathname.includes('infographic')){
            tagType = "infographic"
            tagList = infographicTag
        }
        let matchedCategory = tagList.find(
            ({ category_thai }: { category_thai: string }) =>
                category_thai === category
        )
        let payload: {
            pathname: string
            state?: { category?: string, dashboardType?: DashboardType, infographicType?: InfographicType }
            search?: string
        } = {
            pathname: pathname,
        }
        let query_list = []

        if(matchedCategory){
            if(matchedCategory.category_eng !== "privileged"){
                payload['state'] = {category: matchedCategory.category_eng}
                if(matchedCategory.category_thai !== "ทั้งหมด"){
                    query_list.push(
                        "category=" +
                            matchedCategory.category_eng
                    )
                }
            } else { // privileged case
                if(tagType === "dashboard"){
                    payload['state'] = {dashboardType: matchedCategory.category_eng}
                    query_list.push(
                        "dashboardType=" +
                            matchedCategory.category_eng
                    )
                } else { // infographic case
                    payload['state'] = {infographicType: matchedCategory.category_eng}
                    query_list.push(
                        "infographicType=" +
                            matchedCategory.category_eng
                    )
                }
            }
        }
        
        if (query_list.length > 0) {
            payload["search"] = "?" + query_list.join("&")
        }

        history.push(payload)
        window.scrollTo(0,0)
    },[history])

    const handleLogOut = useCallback(() => {
        Cookies.remove("token")
        setUser(getUserAccount())
        if(window.location.search){
            // remove ?from=register
            if(window.location.search.includes('from')){
                window.location.href = window.location.pathname
            }else{
                window.location.reload()
            }
        } else {
            window.location.reload()
        }
    },[])

    const handleCloseAlert = () => {
        setOpenAlert(false)
        window.sessionStorage.setItem("pendingStatusSeen", "true")
    }

    // Set up an event listener for changes in localStorage
    window.addEventListener("storage", function (event) {
        if (event.key === "isLoggedIn") {
            setIsLoggedIn(window.localStorage.getItem("isLoggedIn") === "true")
            console.log(window.localStorage.getItem("isLoggedIn") === "true")
        }
    })

    useEffect(() => {
        setIsLoggedIn(window.localStorage.getItem("isLoggedIn") === "true")
    }, [])

    const href = window.location.href
    const hostname = window.location.host
    const redirectUrl = href.split(hostname)[1]

    const theme = createTheme(NavBarTheme)
    // const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);

    useEffect(() => {
        let prevScrollPos = 0
        const handleScroll = () => {
          const currentScrollPos = window.scrollY;
          const navbar = document.getElementById("appBar");
        //   const content = document.getElementById(`.${}`);
        
          if(navbar){
            if (currentScrollPos < 70 || prevScrollPos > currentScrollPos) {
                navbar.style.top = "0";
                setIsCollapse(false)
                // content.style.marginTop = "50px";
              } else {
                navbar.style.top = "-70px"; 
                setIsCollapse(true)
                // content.style.marginTop = "0";
              }

          }
          
    
          prevScrollPos = currentScrollPos;
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);

    return (
        <ThemeProvider theme={theme}>
            <LogInPopUp open={openLogIn} setOpen={setOpenLogIn} redirect_uri={redirectUrl} />
            <DrawerLeft
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            activeAccordion={activeAccordion}
            setActiveAccordion={setActiveAccordion} />
            {
                user.getIsLoggedIn() ? 
                <DrawerRight
                openDrawer={openDrawer}
                setOpenDrawer={setOpenDrawer} />
                :
                <></>
            }
            
            <AppBar
                id="appBar"
                sx={openDrawer !== "" ? {
                    boxShadow: 'none'
                }
                :
                {
                    transition: 'top 0.3s',
                    zIndex: '100',
                    top:'0'
                }
            }>
                <Container
                sx={{
                    padding:{
                        md:"0 42px !important",
                        sm:'0 42px !important',
                        xs:'0 10px !important'
                    }
                }}>
                    <Toolbar
                        variant="dense"
                        sx={{ width: "100%", transition:'none' }}
                        disableGutters
                    >
                        <Box
                            flexGrow={1}
                            sx={{
                                display: "flex",
                                color: "black",
                                justifyContent: "space-between",
                            }}
                        >   
                        <Box display='flex' justifyContent='flex-start'>
                            <Box display="flex" alignItems="center">
                                    <Box display={{sm:'flex', xs:'none', md:'none'}} alignItems='center' marginRight='5px'>
                                        <MenuIcon 
                                        sx={{
                                            cursor:'pointer', 
                                            padding:'5px', 
                                            borderRadius: '30px',
                                            width:'40px',
                                            height:'40px',
                                            color: '#194B7E',
                                            ":hover": {
                                                backgroundColor: "rgba(32,32,32,0.1)",
                                            }
                                        }}
                                        onClick={() => setOpenDrawer("left")} />
                                    </Box>
                                    <Box display={{sm:'none', xs:'flex', md:'none'}} alignItems='center' marginRight='5px'>
                                        {
                                            openDrawer === "left" ? 
                                            <CloseIcon
                                                sx={{
                                                    cursor:'pointer', 
                                                    padding:'5px', 
                                                    borderRadius: '30px',
                                                    width:'40px',
                                                    height:'40px',
                                                    color: '#194B7E',
                                                    ":hover": {
                                                        backgroundColor: "rgba(32,32,32,0.1)",
                                                    }
                                                }}
                                                onClick={() => {
                                                    setOpenDrawer("")
                                                    setActiveAccordion("")
                                                }}
                                            />
                                            :
                                            <MenuIcon 
                                            sx={{
                                                cursor:'pointer', 
                                                padding:'5px', 
                                                borderRadius: '30px',
                                                width:'40px',
                                                height:'40px',
                                                color: '#194B7E',
                                                ":hover": {
                                                    backgroundColor: "rgba(32,32,32,0.1)",
                                                }
                                            }}
                                            onClick={() => setOpenDrawer("left")} />
                                        }
                                        
                                        
                                    </Box>
                                        <a
                                            className="item"
                                            href="/"
                                            style={{
                                                height: "60px",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Box id="logo-horizontal" display={{md: 'flex', sm:'flex', xs:'none'}}>
                                                <img
                                                    src={logo}
                                                    alt="logo"
                                                    className={style.imgLogo}
                                                />
                                            </Box>
                                            <Box id="logo-square" display={{md: 'none', sm:'none', xs:'flex'}} height="100%">
                                                <img
                                                    src={logo}
                                                    alt="logo"
                                                    style={{width:'151px', objectFit: 'contain',margin:'auto 0'}}
                                                />
                                            </Box>
                                            
                                        </a>
                                    </Box>
                                <Box display={{md: 'flex', sm: 'none', xs: 'none'}}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyItems: "start",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <DropdownMenu
                                            isCollapse={isCollapse}
                                            header="แดชบอร์ดท่องเที่ยว"
                                            toPathname="/dashboard"
                                            width={115}
                                            options={dashboard_tag.map(
                                                ({ category_thai, category_eng }) => ({
                                                    key:category_eng,
                                                    name:
                                                        category_thai === "ทั้งหมด"
                                                            ? "แดชบอร์ดทั้งหมด"
                                                            : category_eng === 'privileged' 
                                                            ? <>แดชบอร์ดพิเศษ <Crown style={{width:'11px', marginLeft: '10px', marginBottom:"-2px"}}/></>
                                                            : <span style={{marginLeft:'20px'}}>{category_thai}</span>
                                                            ,
                                                    handleClick: (() =>
                                                        handleClickPathname(
                                                            '/dashboard',
                                                            category_thai
                                                        )),
                                                    isShowing:
                                                        category_thai !==
                                                            "แดชบอร์ดพิเศษ" ||
                                                        (isLoggedIn &&
                                                            user.getIsPrivileged()),
                                                })
                                            )}
                                        />
                                        <DropdownMenu 
                                        isCollapse={isCollapse}
                                        header='อินโฟกราฟิก'
                                        toPathname="/infographic"
                                        width={85}
                                        options={infographicTag.map(
                                            ({ category_thai, category_eng }) => ({
                                                key:category_eng,
                                                name:
                                                    category_thai === "ทั้งหมด"
                                                        ? "อินโฟกราฟิกทั้งหมด"
                                                        : category_eng === 'privileged' 
                                                        ? <>อินโฟกราฟิกพิเศษ <Crown style={{width:'11px', marginLeft: '10px', marginBottom:"-2px"}}/></>
                                                        : <span style={{marginLeft:'20px'}}>{category_thai}</span>
                                                        ,
                                                handleClick: (() =>
                                                    handleClickPathname(
                                                        '/infographic',
                                                        category_thai
                                                    )) ,
                                                isShowing:
                                                    category_thai !==
                                                        "อินโฟกราฟิกพิเศษ" ,
                                                isDisabled: !['ทั้งหมด','การเดินทาง'].includes(category_thai)
                                            })
                                        )}
                                        />
                                        <DropdownMenu
                                            isCollapse={isCollapse}
                                            header="แค็ตตาล็อกข้อมูล"
                                            toExternalLink="https://catalog.travellink.go.th/"
                                            width={110}
                                            options={[
                                                {
                                                    key: "1",
                                                    name: "ทั้งหมด",
                                                    ahref: "https://catalog.travellink.go.th/",
                                                    isNewTab: true
                                                    
                                                },
                                                {
                                                    key: "2",
                                                    name: "สถานที่พักแรม",
                                                    ahref: "https://catalog.travellink.go.th/group/hotel",
                                                    isNewTab: true
                                                },
                                                {
                                                    key: "3",
                                                    name: "แหล่งท่องเที่ยว",
                                                    ahref: "https://catalog.travellink.go.th/group/attraction",
                                                    isNewTab: true
                                                },
                                                {
                                                    key: "4",
                                                    name: "กิจกรรมท่องเที่ยว",
                                                    ahref: "https://catalog.travellink.go.th/group/activities",
                                                    isNewTab: true
                                                },
                                            ]}
                                        />
                                        <DropdownMenu
                                            isCollapse={isCollapse}
                                            header="รู้จักเรา"
                                            toPathname="/about-us"
                                            width={60}
                                            options={[
                                                {
                                                    key: "1",
                                                    name: "Mission ของโครงการ",
                                                    ahref: "/about-us#vision",
                                                    isNewTab: false,
                                                },
                                                {
                                                    key: "2",
                                                    name: "ทำไมถึงเกิดโครงการนี้",
                                                    ahref: "/about-us#why",
                                                    isNewTab: false,
                                                },
                                                {
                                                    key: "3",
                                                    name: "อะไรคือคุณค่าของเรา",
                                                    ahref: "/about-us#value",
                                                    isNewTab: false,
                                                },
                                                {
                                                    key: "4",
                                                    name: "ความเห็นของผู้เกี่ยวข้อง",
                                                    ahref: "/about-us#comment",
                                                    isNewTab: false,
                                                },
                                                {
                                                    key: "5",
                                                    name: "พันธมิตร",
                                                    ahref: "/about-us#partner",
                                                    isNewTab: false,
                                                },
                                                {
                                                    key: "6",
                                                    name: "ติดต่อเรา",
                                                    ahref: "/contact",
                                                    isNewTab: false,
                                                },
                                            ]}
                                        />
                                    </Box>
                                </Box>
                        </Box>
                            {!(isLoggedIn && user.getIsLoggedIn()) ? (
                                <div className={style.rightMenu}>
                                    {/* <SearchBox /> */}
                                   
                                    <TVLButton
                                    color="tertiary"
                                    size="small"
                                    sx={{
                                        padding: {
                                            md: "8px 24px",
                                            sm: "8px 24px",
                                            xs:"8px 12px"
                                        },
                                        fontSize: "14px",
                                        fontWeight: "400",
                                        lineHeight: "16px",
                                        marginRight: '5px'
                                    }}
                                    onClick={() => setOpenLogIn(true)}>
                                        ลงชื่อเข้าใช้
                                    </TVLButton>
                                    <a className=" item" href="/registration">
                                        <TVLButton
                                            size="small"
                                            sx={{
                                                padding:  {
                                                    md: "8px 24px",
                                                    sm: "8px 24px",
                                                    xs:"8px 12px"
                                                },
                                                fontSize: "14px",
                                                fontWeight: "400",
                                                lineHeight: "16px",
                                                display:{
                                                    md: 'inline-flex',
                                                    sm: 'inline-flex',
                                                    xs: 'none'
                                                }
                                            }}
                                        >
                                            ลงทะเบียน
                                        </TVLButton>
                                    </a>
                                </div>
                            ) : (
                                <>
                                {
                                    openDrawer === "right" ?
                                    <CloseIcon
                                    sx={{
                                        cursor:'pointer', 
                                        padding: '5px',
                                        borderRadius: '30px',
                                        width:'40px',
                                        height:'40px',
                                        marginY: 'auto',
                                        color: '#194B7E',
                                        ":hover": {
                                            backgroundColor: "rgba(32,32,32,0.1)",
                                        }
                                    }}
                                    onClick={() => {
                                        setOpenDrawer("")
                                    }}
                                />
                                :
                                    <Profile 
                                    isCollapse={isCollapse}
                                    handleLogOut={() => handleLogOut()}
                                    setOpenDrawer={setOpenDrawer} />
                                }
                                
                                </>
                                
                                
                            )}
                        </Box>
                        
                    </Toolbar>
                </Container>
            </AppBar>
            {openAlert && !pendingStatusSeen ? (
                <Alert severity="warning" sx={{ justifyContent: "center" }}>
                    สิทธิพิเศษของท่านกำลัง
                    <span style={{ color: "#EF6C00" }}>รอดำเนินการ</span>
                    ยืนยันโดยแอดมินของ {user.getOrgPending().join(" และ ")}{" "}
                    ในระหว่างนี้ท่านสามารถใช้งานแดชบอร์ดทั่วไปได้ปกติ
                    <CloseRoundedIcon
                        sx={{
                            width: "20px",
                            height: "20px",
                            display: "inline-block",
                            marginLeft: "20px",
                            verticalAlign: "sub",
                            cursor: "pointer",
                        }}
                        onClick={() => handleCloseAlert()}
                    />
                </Alert>
            ) : (
                <></>
            )}
        </ThemeProvider>
    )
}

export default Navbar
