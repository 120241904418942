import React from 'react'
import {
    Box,
    Container,
    Grid
} from '@mui/material'
import style from './Welcome.module.css'
import TVLButton from '../../../components/shared/TVLButton/TVLButton'
import macBook from "../../../assets/home/macBook.png"
import { Swiper, SwiperSlide } from './Swiper/Swiper'
import { ReactComponent as Connect } from '../../../assets/home/connectSvg.svg'
import { ReactComponent as ArrowDown } from '../../../assets/home/arrowDown.svg'
import { car } from '../../../assets/home/animation'
import { useHistory } from 'react-router-dom'
import { getUserAccount } from '../../../utils/login'

const SwiperWelcome = ({imgList}: {imgList: string[]}) => {
    return (
        <Swiper
        spaceBetween={0}
        autoplay= {{
            delay: 3000,
        }}
        loop>
            {
                imgList.map((imgUrl, i) => (
                    <SwiperSlide key={i}>
                        <img src={imgUrl} className={style.slideImg}></img>
                    </SwiperSlide>
                ))
            }
            
        </Swiper>
    )
}

const Welcome = () => {
    const imgList = [
        "https://res.cloudinary.com/dh4e0v43k/image/upload/v1706770945/dashboard/new_provincial_page1_mqgwcv.png",
        "https://res.cloudinary.com/dh4e0v43k/image/upload/v1708334250/Screenshot_2024-02-19_121658_osnmwy.png",
        "https://res.cloudinary.com/dh4e0v43k/image/upload/v1706771134/dashboard/new_provincial_recovery_n9kf2d.png"
    ]
    const history = useHistory()

    const user = getUserAccount()

    const handleClickToProductList = () => {
        const searchElement = document.querySelector('#product-list') 
        if(searchElement){
            searchElement.scrollIntoView({behavior:'smooth',block:'start'})
        }  
    }
  return (
    <Box 
    minHeight={{md:'calc(100vh - 60px)', sm: 'none', xs: 'none'}} 
    // minHeight={{md:'min(calc(100vh - 60px), 650px)', sm: 'none', xs: 'none'}} 
    position='relative' 
    display='flex' 
    justifyContent='center'>
        <Box height='560px' className={style.gredientBackground} />
        <Container sx={{padding:{md:'0 !important'}, marginY:'auto', top: '10px'}}>
            <Grid container justifyContent='space-between'>
                {/* PC left side */}
                <Grid item md={5} display={{md:'block', sm:'none', xs:'none'}}paddingLeft='35px' position='relative'>
                    <Connect style={{position: 'absolute', top: '93px', left: '-775px', zIndex: '-1'}} />
                    <img src={car} className={style.car} />
                    {/* <Box className={style.orgName} marginBottom='30px'>TRAVEL LINK</Box> */}
                    <Box className={style.slogan} marginBottom='30px' marginTop='50px'>
                        เชื่อมข้อมูล
                        <br />
                        ท่องเที่ยว
                        <br />
                        ให้ถึงกัน
                    </Box>
                    <Box className={style.text} marginBottom="50px">
                    เราคือผู้เชื่อมโยงข้อมูลท่องเที่ยว
                    <br />
                    ภายใต้ความร่วมมือของรัฐและเอกชน
                    </Box>
                    <Box textAlign='left'>
                    {
                        !user.getIsLoggedIn() ?

                        <a href='/registration'>
                            <TVLButton
                            size='large'
                            sx={{
                                fontSize: "20px",
                                padding: "10px 48px",
                                marginRight: '10px',
                                marginBottom: '10px'
                            }}
                            >
                                ลงทะเบียน
                            </TVLButton>
                        </a>
                        :
                        <></>
                    }
                    <a
                    onClick={() => {
                        history.push('/dashboard')
                        window.scrollTo(0,0)
                    }}>
                        <TVLButton
                        color='tertiary'
                        size='large'
                        sx={{
                                padding: "10px 30px",
                                fontSize: "20px",
                                marginBottom: '10px'
                            }}>
                                สำรวจแดชบอร์ด
                        </TVLButton>
                        
                    </a>
                    </Box>
                    
                </Grid>

                {/* Mobile/Tablet Slogan */}
                <Grid item xs={12} sm={12} display={{md:'none', sm:'flex', xs:'flex'}} justifyContent='center'>
                    <Connect className={style.connect} />
                    <Box className={style.slogan} marginBottom='30px' marginTop={{sm: '80px', xs:'57px'}} textAlign='center' zIndex={1}>
                        เชื่อมข้อมูล
                        <br />
                        ท่องเที่ยว
                        <br />
                        ให้ถึงกัน
                    </Box>

                </Grid>

                {/* Laptop carousel */}
                <Grid item md={6} sm={12} position='relative' zIndex={2} marginTop={{md:'20px', sm:'30px', xs:'20px'}} width='100%'>
                    <img src={macBook} className={style.macBookImg}></img>
                    <Box position='absolute' sx={{
                        top: {
                            md: '34px',
                            sm: 'calc((77.4vw - 92px) * 0.03)',
                            xs: 'calc((100vw - 92px) * 0.04)'
                        },
                        left: {
                            md: '100px',
                            sm: '0',
                            xs: '0'
                        },
                        width: {
                            md: '715px',
                            sm: 'calc((77.4vw - 92px) * 0.778)',
                            xs: 'calc((100vw - 92px) * 0.778)'
                        },
                        margin: {
                            md: '0',
                            sm:'auto',
                            xs: 'auto'
                        },
                        right: {
                            sm: '0',
                            xs: '0'
                        }
                    }} >
                        <SwiperWelcome 
                        imgList={imgList}/>
                    </Box>
                </Grid>
                
                
                {/* Description Mobile */}
                <Grid item xs={12} display={{md:'none', sm:'block', xs: 'block'}} marginTop='30px' marginBottom='30px'>
                        <Box className={style.text} marginBottom="30px" textAlign='center'>
                        เราคือผู้เชื่อมโยงข้อมูลท่องเที่ยว
                        <br />
                        ภายใต้ความร่วมมือของรัฐและเอกชน
                        </Box>
                        <Box textAlign='center'>
                            {
                                !user.getIsLoggedIn() ?
                                <a href='/registration' style={{marginLeft: '10px', marginRight: '10px'}}>
                                    <TVLButton
                                    size='medium'
                                    sx={{
                                        padding: {
                                            sm: "10px 48px",
                                            xs: "10px 25px"
                                        },
                                        fontWeight: '500', 
                                        marginBottom:'10px',
                                        fontSize: {
                                            sm: '20px',
                                            xs: '14px'
                                        }
                                    }}
                                    >
                                        ลงทะเบียน
                                    </TVLButton>
                                </a>
                                :
                                <></>
                                
                            }
                        <a
                        onClick={() => {history.push('/dashboard')}}
                        style={{marginLeft: '10px', marginRight: '10px', marginBottom: '10px'}}>
                            <TVLButton
                            color='tertiary'
                            size='medium'
                            sx={{
                                    padding: {
                                        sm: "10px 30px",
                                        xs: "10px 25px"
                                    },
                                    fontWeight: "500",
                                    marginBottom:'10px',
                                    fontSize: {
                                        sm: '20px',
                                        xs: '14px'
                                    }

                                }}>
                                    สำรวจแดชบอร์ด
                            </TVLButton>
                            
                        </a>
                        </Box>
                    </Grid>
                    <ArrowDown className={style.arrowDown} onClick={handleClickToProductList}/>

            </Grid>

        </Container>
    </Box>
  )
}

export default Welcome