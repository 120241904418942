import React, { useMemo, useState} from 'react'
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, ThemeProvider, createTheme } from '@mui/material'
import { useTable, useFlexLayout, useSortBy, usePagination } from 'react-table'
import PaginationElement from '../shared/PaginationElement/PaginationElement'
import { tableTheme } from '../../theme/table'
import DashboardListSkeleton from '../../pages/Dashboard/DashboardList/DashboardListSkeleton/DashboardListSkeleton'

interface ReactTableMUIProps {
    columns: any[],
    data: any[],
    isLoading: boolean,
    setIsLoading: React.Dispatch<boolean>
}

const ReactTableMUI = ({columns, data, isLoading, setIsLoading}: ReactTableMUIProps) => {

    // const [pageIndex, setPageIndex] = useState<number>(0)

    const { 
        getTableProps, 
        getTableBodyProps, 
        headerGroups, 
        prepareRow,
        page,
        gotoPage,
        state: {pageIndex}
        } = useTable({ 
            columns, 
            data,
            initialState:{
                pageIndex: 0,
                pageSize: 20
            } 
        }, 
        useFlexLayout, 
        useSortBy, 
        usePagination);

    const theme = createTheme(tableTheme)
    
    const handlePaginationChange = async (event: React.ChangeEvent<unknown>, page: number) => {
        const searchElement = document.querySelector('.MuiInputBase-root')    
        searchElement?.scrollIntoView({behavior:'smooth',block:'center'})
        setIsLoading(true)
        const delay: () => Promise<void> =() => new Promise((resolve) => {
            setTimeout(() => {
            resolve()
            }, 500); // Simulating an asynchronous operation with a delay
        });
        await delay()
        gotoPage(page - 1)
        setIsLoading(false)

    }
  return (
    <>
    {
        isLoading ?
        <DashboardListSkeleton 
        columns={columns}/>
        :
        <ThemeProvider theme={theme}>
        <TableContainer sx={{marginBottom: '50px'}}>
            <Table stickyHeader {...getTableProps()}>
                <TableHead>
                    {
                        headerGroups.map((headerGroup) => (
                                <TableRow {...headerGroup.getHeaderGroupProps()}>
                                    {
                                        headerGroup.headers.map((column) => (
                                            <TableCell
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps()
                                            )}>
                                                {column.render("Header")}
                                                {column.canSort ? (
                                                        <TableSortLabel
                                                            active={
                                                                column.isSorted!
                                                            }
                                                            // react-table has a unsorted state which is not treated here
                                                            direction={
                                                                column.isSortedDesc
                                                                    ? "desc"
                                                                    : "asc"
                                                            }
                                                        />
                                                    ) : null}
                                            </TableCell>
                                            )
                                        )
                                    }
                                </TableRow>
                            )
                        )
                    }
                    
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {
                        page.map((row) => {
                            prepareRow(row);
                            return (
                            <TableRow {...row.getRowProps()}>
                                {
                                    row.cells.map((cell) => (
                                        <TableCell {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </TableCell>
                                        )
                                    )
                                }
                            </TableRow>
                        )})
                    }

                </TableBody>
            </Table>
        </TableContainer>
        
        {
            data.length === 0 ?
            <Box sx={{
                fontFamily: "Kanit",
                fontSize: "30px",
                fontWeight: "300",
                lineHeight: "45px",
                letterSpacing: "0em",
                textAlign: "center",
                color: "#515151",
                marginTop: "100px",
                marginBottom: "100px"
            }}>
                ไม่พบแดชบอร์ดที่ท่านค้นหา
            </Box>
            :
            <></>
        }
        

        <PaginationElement
        noItems={data.length}
        pageSize={20}
        pageNo={pageIndex + 1}
        handlePaginationChange={handlePaginationChange}
         />


    </ThemeProvider>
    }
    </>
  )
}

export default ReactTableMUI