import { Theme, ThemeOptions, createTheme } from "@mui/material";
import { mergeThemes } from "./merge";


export const navBarBaseTheme: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 950,
            lg: 1200,
            xl: 1536,
        },
    },
    palette: {
        primary: {
            main: "#ffffff",
        },
    },
    typography: {
        fontFamily: "Kanit", // Replace with your desired font family
    },
    components: {
        MuiListItemText: {
            styleOverrides: {
                primary: {
                    fontSize: "14px",
                },
                root: {
                    marginTop: 0,
                    marginBottom: 0,
                },
            },
        },
        MuiSvgIcon:{
            styleOverrides:{
                root:{
                    transition: 'none'
                }
            }
        },
        MuiAppBar:{
            styleOverrides:{
                root:{
                    backgroundColor: "#FFFFFF",
                    fontFamily: "Kanit",
                    zIndex: 1,
                    // position: "relative"
                }
            }
        }
    }
}

const breakpointTheme = (theme: Theme) => ({
    components:{
        MuiAppBar:{
            styleOverrides:{
                root:{
                    [theme.breakpoints.down('sm')]:{
                        zIndex: 1201
                    } 
                }
            }
        }
    }
})

export const NavBarTheme = mergeThemes(navBarBaseTheme, breakpointTheme(createTheme({})))

export const dropdown_theme: ThemeOptions = {
    components:{
        MuiListItemText:{
            styleOverrides:{
                primary:{
                    fontFamily:'Kanit',
                    fontSize: '14px'
                },
                root:{
                    marginTop:0,
                    marginBottom:0,
                    cursor: 'pointer',
                }
            }
        },
        MuiListItem:{
            styleOverrides:{
                root:{
                    ":hover":{
                        color:"#0182FA"
                    },
                    cursor: 'pointer'
                }
            }
        },
        MuiMenu:{
            styleOverrides:{
                list:{
                    paddingTop:'0',
                    paddingBottom:'0',
                    boxShadow:'0px 4px 4px 0px #00000026',
                    border: "0.5px solid #CBCBCB"
                }
            }
        }
    }
}

export const accordionTheme: ThemeOptions = {
    components: {
        MuiListItemButton: {
            styleOverrides:{
                root:{
                    fontFamily: "Kanit",
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    textAlign: "left",
                    color:'#515151'
                }
            }
        },
        MuiListItemText:{
            styleOverrides:{
                root:{
                    color: '#515151'
                }
            }
        }
    }
}

export const profileNavbar: ThemeOptions = {
    typography: {
      fontFamily: 'Kanit'
    },
    components:{
        MuiListItemText:{
            styleOverrides:{
                primary:{
                    fontSize: '14px',
                    color: "#032B42"
                },
                root:{
                    marginTop:0,
                    marginBottom:0,
                    cursor: "pointer"
                }
            }
        },
        MuiAvatar:{
            styleOverrides:{
                root:{
                    width:"35px", 
                    height: "35px", 
                    top: '1px', 
                    bottom: '0', 
                    marginTop: 'auto',
                    marginBottom: 'auto'
                }
            }
        },
        MuiListItemAvatar:{
            styleOverrides:{
                root:{
                    minWidth: '45px'
                }
            }
        },
        MuiListItem:{
            styleOverrides:{
                root:{
                    padding: '12px 16px'
                }
            }
        }
    }

}


export const profileDropdown: ThemeOptions = {
    typography:{
        fontFamily:'Kanit'
    },
    components: {
        MuiListItem:{
            styleOverrides:{
                root:{
                    cursor: 'pointer',
                    ":hover":{
                        color: "#0182FA",
                    }
                }
            }
        },
        MuiListItemText:{
            styleOverrides:{
                root:{
                    fontSize: "14px",
                    fontWeight: "400",
                    lineHeight: "23px",
                    letterSpacing: "0.005em",
                    textAlign: 'left',
                    color: "#515151"
                },
                primary:{
                    fontSize: "14px"
                }
            }
        },
        MuiListItemAvatar:{
            styleOverrides:{
                root:{
                    color: "#515151",
                    display: 'flex',
                    alignItems: 'center'
                }
            }
        },
        MuiPaper:{
            styleOverrides:{
                root:{
                    borderRadius: "0px 0px 12px 12px",
                    boxShadow: "0px 4px 4px 0px #00000026",
                    border: "0.5px solid #CBCBCB"
                }
            }
        }
    }
}