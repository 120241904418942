import React, { useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { DashboardGalleryCard } from '../../../interfaces/DashboardMetadata';
import BookmarkElement from '../../../components/shared/BookmarkElement/BookmarkElement';
import { SnackbarMessage } from '../../../interfaces/Snackbar';
import { bookmarkDashboard, unbookmarkDashboard } from '../../../services/data-connector';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '../../../redux/store';
import { dashboardMetadataAdded, selectDashboardMetadata, selectDashboardStatus } from '../../../redux/features/dashboard/dashboardSlice';
import { updateDashboardMetadataBookmark } from '../../../utils/dashboard';
import { STATUS } from '../../../variable/enum';
import DashboardTagElement from '../../../components/shared/DashboardTagElement/DashboardTagElement';
import ReactTableMUI from '../../../components/ReactTableMUI/ReactTableMUI';
import ImgIcon from './ImgIcon/ImgIcon';
import style from './DashboardList.module.css'
import { getUserAccount } from '../../../utils/login';

interface DashboardListProps {
  isLoading: boolean,
  setIsLoading: React.Dispatch<boolean>,
  dashboardMetadatas: DashboardGalleryCard[]
  searchText: string,
  setOpenSnackBar: React.Dispatch<boolean>,
  setSnackPack: React.Dispatch<React.SetStateAction<readonly SnackbarMessage[]>>,
  handleLoginPopUp: (link: string) => void
  handleLoginPopUpFromBookmark: () => void
}

const DashboardList = ({
  isLoading,
  setIsLoading,
  dashboardMetadatas,
  searchText,
  setOpenSnackBar,
  setSnackPack,
  handleLoginPopUp,
  handleLoginPopUpFromBookmark
}: DashboardListProps) => {

  const handleSortColumn = (rowA: any, rowB: any, columnId: any, desc?: boolean) => {
    if (columnId === 'pageTitle') {

      return rowA.original[columnId].pageTitle.localeCompare(rowB.original[columnId].pageTitle)

    }
    else {
      return rowA.original[columnId].localeCompare(rowB.original[columnId])
    }
    
  }
  
  const user = getUserAccount()
  const columns = useMemo(() => [
    {
      Header: "",
      accessor: 'bookmark',
      width: 50,
      disableSortBy: true,
      Cell: ({cell: { value }}: {cell: {value: {isBookmarked: boolean, setIsBookmarked: React.Dispatch<boolean>, pageId: string, link: string, pageName: string, galleryType: boolean, pageTitle: string}}}) =>  {
        const [isBookmarked, setIsBookmark] = useState<boolean>(value.isBookmarked)
        return (
        <BookmarkElement
        pageId={value.pageId}
        isBookmarked={isBookmarked}
        setIsBookmarked={setIsBookmark}
        setOpenSnackBar={setOpenSnackBar}
        setSnackPack={setSnackPack}
        urlLink={value.link}
        bookmarkTooltipText={useMemo(() => (value.galleryType ? 
          <>กดเพื่อติดตามแดชบอร์ดหน้า<br /><span style={{fontWeight:'500', marginLeft: '5px', whiteSpace:'nowrap'}}>{value.pageName}</span></>
          :
          "กดเพื่อติดตามแดชบอร์ดหน้านี้"),[])}
        unbookmarkTooltipText="เลิกติดตามแดชบอร์ดนี้"
        bookmarkSnackbarText={`ติดตามแดชบอร์ด "${value.pageTitle}" เรียบร้อยแล้ว`}
        unbookmarkSnackbarText="เลิกติดตามแดชบอร์ดเรียบร้อยแล้ว"
        callBookmarkAPI={bookmarkDashboard}
        callUnbookmarkAPI={unbookmarkDashboard}
        setBookmarkInternal={setBookmarkInternal}
        setUnbookmarkInternal={setUnbookmarkInternal}
        handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
        bookmarkIconStyle={useMemo(() => ({
          marginLeft: "-8px",
          padding: 0,
        }),[])}
         />
      )}
    },
    {
      Header: "รายชื่อแดชบอร์ด",
      accessor: 'pageTitle',
      width: 230,
      sortType: handleSortColumn,
      Cell: ({cell: {value}}: {cell: {value: {pageTitle: string, galleryCode: string, link: string}}}) => (
        <Box  display="flex" justifyContent='flex-start' alignItems='flex-start'>
          {/* <Box className={style.galleryCode} width={45}>
            {value.galleryCode}
          </Box> */}
          {
            user.getIsLoggedIn() ? 
              <a href = {value.link} target="_blank" className={style.pageTitle}>
                {value.pageTitle}
              </a>
              :
              <a onClick={() => handleLoginPopUp(value.link)} className={style.pageTitle}>
                {value.pageTitle}
              </a>
          }
          
        </Box>
      )
    },
    {
      Header: "",
      accessor: 'imgSrc',
      width: 50,
      disableSortBy: true,
      Cell: ({cell: {value}}: {cell: {value: string}}) => (<ImgIcon imgSrc={value}></ImgIcon>)
    },
    {
      
      Header: "จังหวัด",
      accessor: 'relevantProvinces',
      width: 100
    },
    {
      
      Header: "ประเภทข้อมูล",
      accessor: 'galleryClassifier',
      width: 130
    },
    {
      
      Header: "รายละเอียด",
      accessor: 'shortDescription',
      width: 300
    },
    {
      
      Header: "หมวดหมู่",
      accessor: 'tags',
      width: 120,
      disableSortBy: true,
      Cell: ({cell: { value }}: {cell: {value: string[]}}) =>  
        (
          value.map((tag) => (<>
          <DashboardTagElement tag={tag} searchText={searchText} tiny pathname='/dashboard' />
          <br />
          </>))
        )

    }
  ],[dashboardMetadatas])

  const columnMobile = useMemo(() => [
    {
      Header: "",
      accessor: 'bookmark',
      width: 50,
      disableSortBy: true,
      Cell: ({cell: { value }}: {cell: {value: {isBookmarked: boolean, setIsBookmarked: React.Dispatch<boolean>, pageId: string, link: string, pageName: string, galleryType: boolean, pageTitle: string}}}) =>  {
        const [isBookmarked, setIsBookmark] = useState<boolean>(value.isBookmarked)
        return (
        <BookmarkElement
        pageId={value.pageId}
        isBookmarked={isBookmarked}
        setIsBookmarked={setIsBookmark}
        setOpenSnackBar={setOpenSnackBar}
        setSnackPack={setSnackPack}
        urlLink={value.link}
        bookmarkTooltipText={useMemo(() => (value.galleryType ? 
          <>กดเพื่อติดตามแดชบอร์ดหน้า<br /><span style={{fontWeight:'500', marginLeft: '5px', whiteSpace:'nowrap'}}>{value.pageName}</span></>
          :
          "กดเพื่อติดตามแดชบอร์ดหน้านี้"),[])}
        unbookmarkTooltipText="เลิกติดตามแดชบอร์ดนี้"
        bookmarkSnackbarText={`ติดตามแดชบอร์ด "${value.pageTitle}" เรียบร้อยแล้ว`}
        unbookmarkSnackbarText="เลิกติดตามแดชบอร์ดเรียบร้อยแล้ว"
        callBookmarkAPI={bookmarkDashboard}
        callUnbookmarkAPI={unbookmarkDashboard}
        setBookmarkInternal={setBookmarkInternal}
        setUnbookmarkInternal={setUnbookmarkInternal}
        handleLoginPopUpFromBookmark={handleLoginPopUpFromBookmark}
        bookmarkIconStyle={useMemo(() => ({
          marginLeft: "-8px",
          padding: 0,
        }),[])}
         />
      )}
    },
    {
      Header: "รายชื่อแดชบอร์ด",
      accessor: 'pageTitle',
      width: 230,
      Cell: ({cell: {value}}: {cell: {value: {pageTitle: string, galleryCode: string, link: string}}}) => (
        <Box  display="flex" justifyContent='flex-start' alignItems='flex-start'>
          {/* <Box className={style.galleryCode} width={45}>
            {value.galleryCode}
          </Box> */}
          {
            user.getIsLoggedIn() ? 
              <a href = {value.link} target="_blank" className={style.pageTitle}>
                {value.pageTitle}
              </a>
              :
              <a onClick={() => handleLoginPopUp(value.link)} className={style.pageTitle}>
                {value.pageTitle}
              </a>
          }
          
        </Box>
      )
    },
    {
      
      Header: "จังหวัด",
      accessor: 'relevantProvinces',
      width: 100
    },
    {
      
      Header: "หมวดหมู่",
      accessor: 'tags',
      width: 120,
      disableSortBy: true,
      Cell: ({cell: { value }}: {cell: {value: string[]}}) =>  
        (
          value.map((tag) => (<>
          <DashboardTagElement tag={tag} searchText={searchText} tiny pathname='/dashboard' />
          <br />
          </>))
        )

    }
  ],[dashboardMetadatas])
  const dispatch = useDispatch<AppDispatch>()
  const dashboardStatus = useSelector(selectDashboardStatus)
  const dashboardMetadatasFull = useSelector(selectDashboardMetadata)

  const setBookmarkInternal = (pageId: string) => {
      if(dashboardStatus === STATUS.SUCCEEDED){
          let dashboardMetadataTmp = updateDashboardMetadataBookmark(dashboardMetadatasFull, {pageId: pageId, isBookmarked: true})
          dispatch(dashboardMetadataAdded(dashboardMetadataTmp))
      }
  }

  const setUnbookmarkInternal = (pageId: string) => {
      if(dashboardStatus === STATUS.SUCCEEDED){
          let dashboardMetadataTmp = updateDashboardMetadataBookmark(dashboardMetadatasFull, {pageId: pageId, isBookmarked: false})
          dispatch(dashboardMetadataAdded(dashboardMetadataTmp))
      }
  }
  const dashboardRows = useMemo( () => dashboardMetadatas.flatMap((dashboardGallery) => dashboardGallery.galleryPages.map(
    ({isBookmarked, pageId, link, pageTitle, relevantProvinces, pageName}) => {

      return({
      bookmark: {isBookmarked, setIsBookmarked: () => {}, pageTitle, pageId, link, pageName, galleryType: dashboardGallery.galleryType},
      pageTitle: {pageTitle,galleryCode: dashboardGallery.galleryCode, link},
      imgSrc: dashboardGallery.imgUrl,
      relevantProvinces: relevantProvinces.length > 0 ? relevantProvinces.join(', ') : '-',
      galleryClassifier: dashboardGallery.galleryClassifier,
      shortDescription: dashboardGallery.shortDescription.replace('<pageName>',pageName),
      tags: dashboardGallery.tags
    })})), [dashboardMetadatas])


  return (
    <Box>
      <Box display={{md: 'block', sm: 'none', xs: 'none'}}>
          <ReactTableMUI
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          data={dashboardRows}
          columns={columns}
          />
      </Box>
      <Box display={{md: 'none', sm: 'block', xs: 'block'}}>
          <ReactTableMUI
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          data={dashboardRows}
          columns={columnMobile}
          />
      </Box>
    </Box>
      

  )
}

export default DashboardList